import React, { useEffect } from 'react';
import '../assets/scss/modal.scss';
import PaymentCard from '../components/PaymentCard.jsx';

const ModalDesktop = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        if (isOpen) {
            // Get the current selected picture
            const elementNavCurrentBackgroundImage = document.querySelector('.flip .column__item-img')?.style.backgroundImage;
            // console.log("elementNav_current_backgroundImage =", elementNavCurrentBackgroundImage);

            if (elementNavCurrentBackgroundImage) {
                // Remove the 'url("' at the start and '")' at the end
                const cleanedUrl = elementNavCurrentBackgroundImage.slice(5, -2);

                // Find the index of "/assets"
                const index = cleanedUrl.indexOf('/assets');

                // Extract the path from "/assets" onwards
                const path = cleanedUrl.slice(index);

                const newImageUrl = path;
                // console.log("newImageUrl =", newImageUrl);

                // Apply the new image URL
                const child = document.querySelector('.card-image');
                if (child) {
                    child.src = newImageUrl;
                }
            }
        }
    }, [isOpen]); // Depend on isOpen to run the effect when the modal opens or closes

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close-btn" onClick={onClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 18L18 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                {children}
                <PaymentCard />
            </div>
        </div>
    );
};

export default ModalDesktop;
