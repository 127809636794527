import React, { useEffect, useState } from 'react';
import transition from '../transition';
import '../assets/scss/grid_to_slider.scss';
import { animation } from '../assets/js/grid_to_slider/index.js'
import ModalMobile from '../components/ModalMobile.jsx';
import { useLocation } from 'react-router-dom';

const GridToSliderMobile = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // console.log('isModalOpen =', isModalOpen)

  // Select the close button element
  const element = document.getElementById('close-button');

  const openModal = () => {
    setIsModalOpen(true);
    // Hide the close button of the image slider underneath the modal content after opening the modal
    if (element) {
      element.style.display = 'none';
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // Display the close button of the image slider underneath the modal content after closing the modal
    element.style.display = 'block';
  };

  const imageData = [
    { position: 'pos-1', imageUrl: '/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp' },
    { position: 'pos-2', imageUrl: '/assets/img/lnacarla/kizomba-station-23-04-22/image_39.webp' },
    { position: 'pos-3', imageUrl: '/assets/img/lnacarla/kizomba-station-08-04-23/image_38.webp' },
    { position: 'pos-4', imageUrl: '/assets/img/lnacarla/lunar-festival-sat-night-04-2023/image_91.webp' },
    { position: 'pos-5', imageUrl: '/assets/img/lnacarla/lunar-festival-fri-night-04-2023/image_48.webp' },

    { position: 'pos-6', imageUrl: '/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-41.webp' },
    { position: 'pos-7', imageUrl: '/assets/img/lnacarla/lunar-festival-fri-night-04-2023/image_99.webp' },
    { position: 'pos-8', imageUrl: '/assets/img/lnacarla/kizzaffaire-sun-23/KIZZAFFAIRE2023-SUNDAY-LNACARLA-64.webp' },
    { position: 'pos-9', imageUrl: '/assets/img/lnacarla/lok-paris-11-12-22/image_28.webp' },
    { position: 'pos-10', imageUrl: '/assets/img/lnacarla/kizzaffaire-thur-23/AB9C07BF-7A7A-4258-8565-153C9B9007CB.webp' },

    { position: 'pos-11', imageUrl: '/assets/img/lnacarla/kizzaffaire-thur-23/5FD52DFA-F7CB-40C7-8D7A-9BCFA29C7DA7.webp' },
    { position: 'pos-12', imageUrl: '/assets/img/lnacarla/kizomba-station-26-03-22/image_90.webp' },
    { position: 'pos-13', imageUrl: '/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-45.webp' },
    { position: 'pos-14', imageUrl: '/assets/img/lnacarla/wakanda-fri-2023/image_35.webp' },
    { position: 'pos-15', imageUrl: '/assets/img/lnacarla/lok-paris-11-12-22/image_28.webp' },

    { position: 'pos-16', imageUrl: '/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp' },
    { position: 'pos-17', imageUrl: '/assets/img/lnacarla/wakanda-fri-2023/image_168.webp' },
    { position: 'pos-18', imageUrl: '/assets/img/lnacarla/lunar-weekend-sat-night-04-2022/image_170.webp' },
    { position: 'pos-19', imageUrl: '/assets/img/lnacarla/lok-paris-11-12-22/image_57.webp' },
    { position: 'pos-20', imageUrl: '/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-60.webp' },

    { position: 'pos-21', imageUrl: '/assets/img/lnacarla/wakanda-fri-2023/image_182.webp' },
    { position: 'pos-22', imageUrl: '/assets/img/lnacarla/kizomba-station-18-09-21/image_57.webp' },
    { position: 'pos-23', imageUrl: '/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-73.webp' },
    { position: 'pos-24', imageUrl: '/assets/img/lnacarla/lok-paris-22-05-22/image_121.webp' },
    { position: 'pos-25', imageUrl: '/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-74.webp' },

    { position: 'pos-26', imageUrl: '/assets/img/lnacarla/wakanda-fri-2023/image_17.webp' },
    { position: 'pos-27', imageUrl: '/assets/img/lnacarla/lunar-weekend-fri-social-04-2022/image_4.webp' },
    { position: 'pos-28', imageUrl: '/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-139.webp' },
    { position: 'pos-29', imageUrl: '/assets/img/lnacarla/wakanda-fri-2023/image_67.webp' },
    { position: 'pos-30', imageUrl: '/assets/img/lnacarla/lok-paris-11-12-22/image_83.webp' },
  ];

  // The useEffect hook is used to ensure that your code runs after the component has mounted and the initial render is complete.
  // The empty dependency array ([]) ensures that the effect runs exactly once after the initial rendering.
  useEffect(() => {

    /*
        To wait until a specific element exists in the DOM before executing code like querying with document.querySelectorAll, 
        you can set up a mutation observer. 
        A mutation observer watches for changes in the DOM and executes a callback when those changes occur. 
        This can be especially useful in scenarios where you are integrating React 
        with other libraries or scripts that might manipulate the DOM independently of React's rendering cycle.        
    */
    const observer = new MutationObserver(mutations => {
      for (let mutation of mutations) {
        if (mutation.type === 'childList') {
          const gridItems = document.querySelectorAll('.grid');
          if (gridItems.length > 0) {
            // console.log('gridItems =', gridItems); 
            animation()
            observer.disconnect(); // Stop observing once the elements are found
          }
        }
      }
    });

    // Start observing for changes in the document
    observer.observe(document.body, {
      childList: true,  // observe direct children
      subtree: true,    // and lower descendants too
    });

    // Cleanup observer on component unmount
    return () => observer.disconnect();

  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    // Call animation on route change
    animation();
  }, [location]); // Dependency on location to trigger on route change

  return (
    <div>

      <main className="demo-1 loading">
        {/* Modal */}
        <div className="buy-picture-mobile">
          <span className="buy-picture-button-mobile" onClick={openModal}>YOU WANT THIS (HD)</span>
        </div>

        {/* Mobile Modal */}
        <ModalMobile isOpen={isModalOpen} onClose={closeModal}>
          <h2>GET YOUR PICURE IN HD - 1/30</h2>
          <div className="card__mobile  info-card">
            <div className="card-column">
              {/* <img className="card-image" src="" alt="" /> */}
            </div>
            <div className="card-column">
              <p className="card-title">HD DIGITAL FORMAT</p>
              <p className="card-price">Price: $10</p>
              <p className="card-limited">LIMITED EDITION</p>
            </div>
            <div className="card-column">
              <p className="card-title">HD PRINT FORMAT</p>
              <p className="card-price">Price: $70</p>
              <p className="card-limited">LIMITED EDITION</p>
            </div>
          </div>
        </ModalMobile>

        <div className="intro-grid intro-grid--labels">
          <span className="intro-grid__label pos-1 oh"><span className="oh__inner">Salsa</span></span>
          <span className="intro-grid__label pos-2 oh"><span className="oh__inner">Kizomba</span></span>
          <span className="intro-grid__label pos-3 oh"><span className="oh__inner">III</span></span>
          <span className="intro-grid__label pos-4 oh"><span className="oh__inner">Shooting</span></span>
          <span className="intro-grid__label pos-5 oh"><span className="oh__inner">Magic</span></span>
          <span className="intro-grid__label pos-6 oh"><span className="oh__inner">Corporate</span></span>
          <span className="intro-grid__label pos-7 oh"><span className="oh__inner">Sensual</span></span>
          <span className="intro-grid__label pos-8 oh"><span className="oh__inner">Afro</span></span>
        </div>

        <div className="intro-grid intro-grid--images">
          {imageData.map((image, index) => (
            <div
              key={index}
              id={`${image.position}`}
              className={`intro-grid__img ${image.position}`}
              style={{ backgroundImage: `url(${image.imageUrl})` }}
            ></div>
          ))}
        </div>

        <div className="intro-title">
          <h2 className="intro-title__main oh"><span className="oh__inner">lnacarla</span></h2>
          <span className="intro-title__sub oh"><span className="oh__inner">Ouvrez les yeux</span></span>
        </div>
        <div className="slider-title">
          <h3 className="slider-title__main oh"><span className="oh__inner">LNACARLA</span></h3>
          <p className="slider-title__desc" > GET THIS PICTURE HD FORMAT</p>
        </div>
        <div className="controls">
          <button id="close-button" className="unbutton close">X</button>
          <nav className="nav">
            <button className="unbutton nav__item">prev</button>
            <span className="nav__index">
              <span>1</span>/<span>15</span>
            </span>
            <button className="unbutton nav__item">next</button>
          </nav>
        </div>
      </main>
    </div>
  );
};

export default transition(GridToSliderMobile);
