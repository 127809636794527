import React, { useEffect, useState } from 'react';
import transition from '../transition';
import '../assets/scss/grid_view_switch.scss';
import { animation } from '../assets/js/grid_view_switch/index.js'
import { useLocation } from 'react-router-dom';
import ModalDesktop from '../components/ModalDesktop.jsx';
import GridToSliderMobile from '../components/GridToSliderMobile.jsx';

const Home = () => {

  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const initialSelectedPicture = "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp"

  const columnsData = [
    // First column from the left side
    {
      className: "column column--moveup",
      items: [
        { id: 1, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp", extraClass: "" },
        { id: 2, imageUrl: "/assets/img/lnacarla/kizomba-station-23-04-22/image_39.webp", extraClass: "" },
        { id: 3, imageUrl: "/assets/img/lnacarla/kizomba-station-08-04-23/image_38.webp", extraClass: "" },
        { id: 4, imageUrl: "/assets/img/lnacarla/lunar-festival-sat-night-04-2023/image_91.webp", extraClass: "" },
        { id: 5, imageUrl: "/assets/img/lnacarla/lunar-festival-fri-night-04-2023/image_48.webp", extraClass: "" },
      ],
    },
    // Second column from the left side
    {
      className: "column column--movedown",
      items: [
        { id: 6, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-41.webp", extraClass: "" },
        { id: 7, imageUrl: "/assets/img/lnacarla/lunar-festival-fri-night-04-2023/image_99.webp", extraClass: "" },
        { id: 8, imageUrl: "/assets/img/lnacarla/kizzaffaire-sun-23/KIZZAFFAIRE2023-SUNDAY-LNACARLA-64.webp", extraClass: "" },
        { id: 9, imageUrl: "/assets/img/lnacarla/lok-paris-11-12-22/image_28.webp", extraClass: "" },
        { id: 10, imageUrl: "/assets/img/lnacarla/kizzaffaire-thur-23/AB9C07BF-7A7A-4258-8565-153C9B9007CB.webp", extraClass: "" },
      ],
    },
    // Third column from the left side
    {
      className: "column column--moveup",
      items: [
        { id: 11, imageUrl: "/assets/img/lnacarla/kizzaffaire-thur-23/5FD52DFA-F7CB-40C7-8D7A-9BCFA29C7DA7.webp", extraClass: "" },
        { id: 12, imageUrl: "/assets/img/lnacarla/kizomba-station-26-03-22/image_90.webp", extraClass: "" },
        { id: 13, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-45.webp", extraClass: "" },
        { id: 14, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_35.webp", extraClass: "" },
        { id: 15, imageUrl: "/assets/img/lnacarla/lok-paris-11-12-22/image_28.webp", extraClass: "" },
      ],
    },
    // Fourth column from the left side
    {
      className: "column column--movedown",
      items: [
        { id: 16, imageUrl: initialSelectedPicture, extraClass: "flip" },
        { id: 17, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_168.webp", extraClass: "" },
        { id: 18, imageUrl: "/assets/img/lnacarla/lunar-weekend-sat-night-04-2022/image_170.webp", extraClass: "" },
        { id: 19, imageUrl: "/assets/img/lnacarla/lok-paris-11-12-22/image_57.webp", extraClass: "" },
        { id: 20, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-60.webp", extraClass: "" },
      ],
    },
    // Fifth column from the left side
    {
      className: "column column--moveup",
      items: [
        { id: 21, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_182.webp", extraClass: "" },
        { id: 22, imageUrl: "/assets/img/lnacarla/kizomba-station-18-09-21/image_57.webp", extraClass: "" },
        { id: 23, imageUrl: "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-73.webp", extraClass: "" },
        { id: 24, imageUrl: "/assets/img/lnacarla/lok-paris-22-05-22/image_121.webp", extraClass: "" },
        { id: 25, imageUrl: "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-74.webp", extraClass: "" },
      ],
    },
    // Sixth column from the left side
    {
      className: "column column--movedown",
      items: [
        { id: 26, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_17.webp", extraClass: "" },
        { id: 27, imageUrl: "/assets/img/lnacarla/lunar-weekend-fri-social-04-2022/image_4.webp", extraClass: "" },
        { id: 28, imageUrl: "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-139.webp", extraClass: "" },
        { id: 29, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_67.webp", extraClass: "" },
        { id: 30, imageUrl: "/assets/img/lnacarla/lok-paris-11-12-22/image_83.webp", extraClass: "" },
      ],
    },
  ];


  // Preview of all exclu picture available
  const navItems = [
    { id: 1, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp", current: true },
    { id: 2, imageUrl: "/assets/img/lnacarla/kizomba-station-23-04-22/image_39.webp", current: false },
    { id: 3, imageUrl: "/assets/img/lnacarla/kizomba-station-08-04-23/image_38.webp", current: false },
    { id: 4, imageUrl: "/assets/img/lnacarla/lunar-festival-sat-night-04-2023/image_91.webp", current: false },
    { id: 5, imageUrl: "/assets/img/lnacarla/lunar-festival-sat-night-04-2023/image_181.webp", current: false },

    { id: 6, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-41.webp", current: false },
    { id: 7, imageUrl: "/assets/img/lnacarla/lunar-festival-fri-night-04-2023/image_99.webp", current: false },
    { id: 8, imageUrl: "/assets/img/lnacarla/kizzaffaire-sun-23/KIZZAFFAIRE2023-SUNDAY-LNACARLA-64.webp", current: false },
    { id: 9, imageUrl: "/assets/img/lnacarla/lok-paris-11-12-22/image_28.webp", current: false },
    { id: 10, imageUrl: "/assets/img/lnacarla/kizzaffaire-thur-23/AB9C07BF-7A7A-4258-8565-153C9B9007CB.webp", current: false },

    { id: 11, imageUrl: "/assets/img/lnacarla/lunar-weekend-fri-night-04-2022/image_90.webp", current: false },
    { id: 12, imageUrl: "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-61.webp", current: false },
    { id: 13, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-45.webp", current: false },
    { id: 14, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_35.webp", current: false },
    { id: 15, imageUrl: "/assets/img/lnacarla/kizomba-station-08-04-23/image_128.webp", current: false },

    { id: 16, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_168.webp", extraClass: "flip" },
    { id: 17, imageUrl: "/assets/img/lnacarla/lunar-festival-sat-night-04-2023/image_152.webp", current: false },
    { id: 18, imageUrl: "/assets/img/lnacarla/lunar-weekend-sat-night-04-2022/image_170.webp", current: false },
    { id: 19, imageUrl: "/assets/img/lnacarla/lok-paris-11-12-22/image_57.webp", current: false },
    { id: 20, imageUrl: "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-60.webp", current: false },

    { id: 21, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_182.webp", current: false },
    { id: 22, imageUrl: "/assets/img/lnacarla/kizomba-station-18-09-21/image_57.webp", current: false },
    { id: 23, imageUrl: "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-73.webp", current: false },
    { id: 24, imageUrl: "/assets/img/lnacarla/lok-paris-22-05-22/image_121.webp", current: false },
    { id: 25, imageUrl: "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-74.webp", current: false },

    { id: 26, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_17.webp", current: false },
    { id: 27, imageUrl: "/assets/img/lnacarla/lunar-weekend-fri-social-04-2022/image_4.webp", current: false },
    { id: 28, imageUrl: "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-139.webp", current: false },
    { id: 29, imageUrl: "/assets/img/lnacarla/wakanda-fri-2023/image_67.webp", current: false },
    { id: 30, imageUrl: "/assets/img/lnacarla/lok-paris-11-12-22/image_83.webp", current: false },

    { more: true } // Representing the 'more' item
  ];

  // The useEffect hook is used to ensure that your code runs after the component has mounted and the initial render is complete.
  // The empty dependency array ([]) ensures that the effect runs exactly once after the initial rendering.
  useEffect(() => {
    // Call animation on route change
    animation();
  }, [location]); // Dependency on location to trigger on route change

  return (
    <div>
      <main>
        <div className='desktop-only'>
          <div className="frame">
            <div className="frame__heading">LNACARLA</div>
            <div className="">EXCLUSIVE</div>
          </div>

          <div className="switch">
            {/* <span className="switch__text">Choose your view </span> */}
            <button className="switch__button switch__button--grid unbutton">BACK</button>
            <button className="switch__button switch__button--list unbutton">
              {/* <svg width="22" height="20" viewBox="0 0 22 24"><path d="M0,0 L22,0 L22,18 L0,18 L0,0 Z M0,20 L4,20 L4,24 L0,24 L0,20 Z M6,20 L10,20 L10,24 L6,24 L6,20 Z M12,20 L16,20 L16,24 L12,24 L12,20 Z M18,20 L22,20 L22,24 L18,24 L18,20 Z"></path></svg> */}            GALLERY
            </button>
          </div>
          <div className="buy-picture">
            <span className="buy-picture-button" onClick={openModal}>YOU WANT THIS (HD)</span>
          </div>

          <ModalDesktop isOpen={isModalOpen} onClose={closeModal}>
            <h2>GET YOUR PICTURE IN HD - 1/30</h2>
            <div className="card info-card">
              <div className="card-column">
                <img className="card-image" src="" alt="" />
              </div>
              <div className="card-column desktop">
                <p className="card-title">HD DIGITAL FORMAT</p>
                <p className="card-title">HD PRINT FORMAT</p>
              </div>
              <div className="card-column desktop">
                <p className="card-price">Price: $10</p>
                <p className="card-price">Price: $70</p>
              </div>
              <div className="card-column mobile">
                <p className="card-limited">LIMITED EDITION</p>
                <p className="card-limited">LIMITED EDITION</p>
              </div>
            </div>
          </ModalDesktop>

          <div className="columns">
            {columnsData.map((column, columnIndex) => (
              <div className={column.className} key={columnIndex}>
                <div className="column-inner">
                  {column.items.map((item, itemIndex) => (
                    <figure className="column__item" key={itemIndex}>
                      <div className={`column__item-imgwrap ${item?.extraClass}`}>
                        <div
                          className="column__item-img"
                          style={{ backgroundImage: `url(${item.imageUrl})` }}
                        ></div>
                      </div>
                    </figure>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="content">
            <div className="content__item">
              {/* .flip element will get inserted here */}
            </div>
            <h1 className="content__title">
              <span className="oh"><span>lna</span></span>
              <span className="oh"><span>carla</span></span>
            </h1>
            <nav className="content__nav">
              {navItems.map((item, index) => 
                <div
                  key={index}
                  id={`content__nav-item--${item.id}`}
                  className={`content__nav-item ${item.current ? "content__nav-item--current" : ""}`}
                  style={{ backgroundImage: `url(${item.imageUrl})` }}
                ></div>
              )}
            </nav>
          </div>
        </div>

        <div className='desktop-mobile'>
          <GridToSliderMobile></GridToSliderMobile>
        </div>
      </main>
    </div>
  );
};

export default transition(Home);
