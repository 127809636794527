// PaymentCard.jsx
import React, { useState } from 'react';
import '../assets/scss/payment_card.scss'; // Assuming you have a CSS file for styling

const PaymentCard = () => {
  const [paymentMethod, setPaymentMethod] = useState('paypal');
  const [cardNumber, setCardNumber] = useState('');
  const [cvcNumber, setCvcNumber] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [formatOption, setFormatOption] = useState('');

  const handleCardNumberChange = (e) => {
    const { value } = e.target;
    let newValue = value.replace(/\D/g, ''); // Remove all non-digit characters
    // Break the string into groups of 4 digits and join with a space
    newValue = newValue.substring(0, 16).match(/.{1,4}/g)?.join(' ') ?? '';
    setCardNumber(newValue);
  };

  const handleCardExpiryChange = (e) => {
    let value = e.target.value;

    // First, ensure only numbers or a slash are included
    value = value.replace(/[^\d\/]|^\/+/g, '');

    // Insert slash if there are more than 2 digits and no slash yet, but only when adding characters
    if (value.length > 2 && !value.includes('/')) {
      // Reformat to include the slash correctly
      value = value.slice(0, 2) + '/' + value.slice(2);
    }

    // Allow users to delete the slash if they are backspacing
    if (value.length === 3 && e.target.value.endsWith('/')) {
      // If the user is trying to delete the slash, remove the last digit instead
      value = value.slice(0, 2);
    }

    // Limit to MM/YY format
    if (value.length > 5) {
      value = value.substring(0, 5);
    }

    setCardExpiry(value);
  };

  const handleCvcNumberChange = (e) => {
    const newValue = e.target.value;
    // Check if the input is numeric and does not exceed 3 digits
    if (/^\d*$/.test(newValue) && newValue.length <= 3) {
      setCvcNumber(newValue);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your submission logic here
    // console.log('Form submitted with payment method:', paymentMethod);
  };

  return (
    <div className="PaymentCard-container payment-card">
      <form onSubmit={handleSubmit}>
        <div className="format-selection">
          <label htmlFor="formatOption">Select Format:</label>
          <select
            name="formatOption"
            id="formatOption"
            value={formatOption} // Assuming you have a state variable to track this
            onChange={(e) => setFormatOption(e.target.value)} // Assuming you have a method to update state
          >
            <option value="hdDigital">HD Digital</option>
            <option value="hdPrint">HD Print</option>
          </select>
        </div>
        
        <div>
          <label>Option Payment</label>
        </div>
        <div className="payment-method">
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="paypal"
              checked={paymentMethod === 'paypal'}
              onChange={() => setPaymentMethod('paypal')}
            />
            PayPal
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="visa"
              checked={paymentMethod === 'visa'}
              onChange={() => setPaymentMethod('visa')}
            />
            Visa
          </label>
        </div>

        {/* Display all */}
        {paymentMethod === 'visa' && (
          <div className="card-details">
            <label>Card Number</label>
            <input
              type="text"
              name="cardNumber"
              placeholder="XXXX XXXX XXXX XXXX"
              value={cardNumber}
              onChange={handleCardNumberChange}
            />
            <label>Card Expiry</label>
            <input
              type="text"
              name="cardExpiry"
              placeholder="MM/YY"
              value={cardExpiry}
              onChange={handleCardExpiryChange}
            />
            <label>CVC</label>
            <input
              type="text"
              name="cardCVC"
              placeholder="CVC"
              value={cvcNumber}
              onChange={handleCvcNumberChange}
            />
          </div>
        )}

        <div className="card payment-card">
          <button className="payment-button">Buy this picture (HD)</button>
        </div>
        {/* <button type="submit">Pay Now</button> */}
      </form>
    </div >
  );
};

export default PaymentCard;
