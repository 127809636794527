import { preloadImages } from './utils.js';

// Import the necessary function for preloading images
import gsap from "gsap";

// get other plugins:
import Flip from "gsap/Flip";

gsap.registerPlugin(Flip);

export function animation() {

    // Grid element
    const grid = document.querySelector('.columns');

    // The grid columns iiner elements that move upwards/downwards
    const columnsInner = {
        up: grid.querySelectorAll('.column--moveup > .column-inner'),
        down: grid.querySelectorAll('.column--movedown > .column-inner')
    };

    // Content element
    const content = document.querySelector('.content');

    // Content item element
    const contentItem = content.querySelector('.content__item')

    // Content nav and content nav items
    const contentNav = content.querySelector('.content__nav');
    const contentNavItems = contentNav.querySelectorAll('.content__nav-item');

    // Buy buttons
    const buyPictureButton = document.querySelector('.buy-picture');

    // console.log('contentNav      =', contentNav)
    // console.log('contentNavItems =', contentNavItems )


    // Content title and both title spans
    const contentTitle = content.querySelector('.content__title');
    const contentTitleWords = contentTitle.querySelectorAll('.oh > span');

    // Element that "flips" (GSAP Flip). This element will be inserted in the contentItem

    const flipItem = grid.querySelector('.flip');
    // console.log("flipItem = ", flipItem)

    // Also its parent
    const flipItemParent = flipItem.parentNode;
    // console.log("flipItemParent = ", flipItemParent)

    // The element on the left of the flipItem (when there are only two visible grid items in the viewport)
    const pushItem = grid.querySelector('.push');

    // There are two modes: grid mode and list mode
    const switchMode = document.querySelector('.switch');

    const switchToggle = {
        list: switchMode.querySelector('.switch__button--list'),
        grid: switchMode.querySelector('.switch__button--grid')
    };

    // We start with the grid mode by default
    let currentMode = 'grid';
    switchToggle[currentMode].classList.add('switch__button--current');

    let isAnimating;

    gsap.set([grid, columnsInner.up, columnsInner.down, contentTitleWords, pushItem, flipItem, contentNavItems], { willChange: 'transform, opacity' });

    // Toggle function
    const toggleMode = mode => {
        if (isAnimating || currentMode === mode) return;
        isAnimating = true;
        // Switch current state/class
        switchToggle[currentMode].classList.remove('switch__button--current');
        switchToggle[mode].classList.add('switch__button--current');
        // Set new mode
        currentMode = mode;
        // Call showList or showGrid functions
        switchActions[currentMode]().then(() => isAnimating = false);
    };

    // Show list mode 
    const showList = () => {
        // console.log("showlist =")
        return gsap
            .timeline({
                defaults: {
                    duration: 1.7,
                    ease: 'power2.inOut'
                },
                onStart: () => {
                    // pointer events to auto
                    content.classList.add('content--current');
                }
            })
            .addLabel('start', 0)
            .fromTo(grid, {
                scale: 0.4
            }, {
                scale: 1
            }, 'start')
            // Scale up the button buy picture
            .fromTo(buyPictureButton, {
                scale: 0
            }, {
                scale: 1
            }, 'start')
            .to(columnsInner.up, {
                y: '-200vh'
            }, 'start')
            .to(columnsInner.down, {
                y: '200vh'
            }, 'start')

            // At this point there are only two items/images (flip and push items) in the viewport
            .addLabel('flip', 1.7)

            // contentTitle motion:
            // First show contentTitle
            .add(() => {
                gsap.set(contentTitle, { opacity: 1 });
            }, 'flip-=1')
            // Now slide in each word/span
            .fromTo(contentTitleWords, {
                yPercent: pos => pos ? -200 : 200
            }, {
                duration: 0.85,
                ease: 'power2',
                yPercent: 0
            }, 'start+=0.85')
            // Then switch positions of both words
            .to([...contentTitleWords].map(word => word.parentNode), {
                duration: 1,
                ease: 'power4',
                yPercent: pos => pos ? -43 : 43
            })

            .add(() => {
                // console.log("flipItem = ", flipItem)
                // console.log("pushItem = ", pushItem)

                // Save current state of the flipItem
                    const flipstate = Flip.getState(flipItem);
                    // Insert the flipItem in the contentItem
                    contentItem.appendChild(flipItem);
                    // Animate the element using the GSAP Flip magic
                    Flip.from(flipstate, {
                        duration: 1,
                        ease: 'power4'
                    });
                    // show contentNav
                    // console.log('contentNav =', contentNav)
                    gsap.set(contentNav, { opacity: 1 });
            }, 'flip')
            /* .to(pushItem, { // WARNING => pushItem = null
                duration: 1,
                ease: 'power4',
                xPercent: -100,
                //startAt: {filter: 'brightness(100%)'},
                //filter: 'brightness(60%)'
            }, 'flip') */
            .fromTo(contentNavItems, {
                yPercent: 200,
                opacity: 0
            }, {
                duration: .7,
                ease: 'power4',
                stagger: 0.03,
                yPercent: 0,
                opacity: 1
            }, 'flip')
    };

    // Show grid mode
    const showGrid = () => {

        // Array of image URLs
        // Rebuidl the grid of images before the switch 
        var imageUrls = [
            "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp",
            "/assets/img/lnacarla/kizomba-station-23-04-22/image_39.webp",
            "/assets/img/lnacarla/kizomba-station-08-04-23/image_38.webp",
            "/assets/img/lnacarla/lunar-festival-sat-night-04-2023/image_91.webp",
            "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp",

            "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-41.webp",
            "/assets/img/lnacarla/lunar-festival-fri-night-04-2023/image_99.webp",
            "/assets/img/lnacarla/kizzaffaire-sun-23/KIZZAFFAIRE2023-SUNDAY-LNACARLA-64.webp",
            "/assets/img/lnacarla/lok-paris-11-12-22/image_28.webp",
            "/assets/img/lnacarla/kizzaffaire-thur-23/AB9C07BF-7A7A-4258-8565-153C9B9007CB.webp",

            "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-44.webp",
            "/assets/img/lnacarla/kizzaffaire-thur-23/AB9C07BF-7A7A-4258-8565-153C9B9007CB.webp",
            "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-45.webp",
            "/assets/img/lnacarla/wakanda-fri-2023/image_35.webp",
            "assets/img/lnacarla/lok-paris-11-12-22/image_28.webp",

            "/assets/img/lnacarla/lunar-festival-sat-night-04-2023/image_152.webp",
            "/assets/img/lnacarla/wakanda-fri-2023/image_168.webp",
            "/assets/img/lnacarla/lunar-weekend-sat-night-04-2022/image_170.webp",
            "/assets/img/lnacarla/lok-paris-11-12-22/image_57.webp",
            "/assets/img/lnacarla/kizzaffaire-fri-22/KIZZAFFAIRE2023-FRIDAY-LNACARLA-60.webp",

            "/assets/img/lnacarla/wakanda-fri-2023/image_182.webp",
            "/assets/img/lnacarla/kizomba-station-18-09-21/image_57.webp",
            "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-73.webp",
            "/assets/img/lnacarla/lok-paris-22-05-22/image_121.webp",
            "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-74.webp",

            "/assets/img/lnacarla/wakanda-fri-2023/image_17.webp",
            "/assets/img/lnacarla/lunar-weekend-fri-social-04-2022/image_4.webp",
            "/assets/img/lnacarla/kizzaffaire-sat-23/KIZZAFFAIRE2023-SATURDAY-LNACARLA-139.webp",
            "/assets/img/lnacarla/wakanda-fri-2023/image_67.webp",
            "/assets/img/lnacarla/lok-paris-11-12-22/image_83.webp"
        ];


        // Get all elements with the class 'myBackgroundClass'
        var elements = document.querySelectorAll('.column__item-img');

        // Loop through each element and assign a different background image
        elements.forEach(function (element, index) {
            var imageUrl = imageUrls[index % imageUrls.length]; // This ensures the array loops if there are more elements than images
            element.style.backgroundImage = 'url(' + imageUrl + ')';
        });

        // ======= IMPORTANT TRANSITION SWITCH NODE =======
        // ======= Your new image URL for the FLIP image =====

        // const elementNav_current = document.querySelector('.content__nav-item--current');
        const elementNav_current_backgroundImage = document.querySelector('.content__nav-item--current').style.backgroundImage
        // console.log("elementNav_current_backgroundImage =", elementNav_current_backgroundImage)

        // Remove the 'url("' at the start and '")' at the end
        const cleanedUrl = elementNav_current_backgroundImage.slice(5, -2);

        // Find the index of "/assets"
        const index = cleanedUrl.indexOf('/assets');

        // Extract the path from "/assets" onwards
        const path = cleanedUrl.slice(index);

        const newImageUrl = path;

        // Get all parent elements with the class 'column__item-imgwrap'
        const parentElements = document.querySelectorAll('.flip');


        // Loop through each parent element
        parentElements.forEach(function (parent) {
            // Find the 'column__item-img' child within the current parent element
            const child = parent.querySelector('.column__item-img');

            // Change the background image of the child element
            if (child) {
                child.style.backgroundImage = 'url(' + newImageUrl + ')';
            }
        });

        return gsap
            .timeline({
                defaults: {
                    duration: 1.7,
                    ease: 'power2.inOut'
                },
                onStart: () => {
                    // pointer events to none
                    content.classList.remove('content--current');
                }
            })
            // Scale down the button buy picture
            .fromTo(buyPictureButton, {
                scale: 1
            }, {
                scale: 0
            }, 'start')
            .addLabel('flip', 0)
            .to(contentNavItems, {
                duration: .7,
                stagger: -0.03,
                yPercent: 200,
                opacity: 0,
                onComplete: () => {
                    // hide contentNav
                    gsap.set(contentNav, { opacity: 1 });
                }
            }, 'flip')
            /* .to(pushItem, { // IMPORTANT => pushItem =  null
                duration: 1,
                xPercent: 0
            }, 'flip') */
            .add(() => {
                // Save current state of the flipItem
                const flipstate = Flip.getState(flipItem);
                // Insert the flipItem in the original flipItemParent
                flipItemParent.appendChild(flipItem);
                // Animate the element using the GSAP Flip magic
                Flip.from(flipstate, {
                    duration: 1,
                    ease: 'power2.inOut',
                });
            }, 'flip')

            .addLabel('columns', 1)

            // contentTitle motion:
            .to([...contentTitleWords].map(word => word.parentNode), {
                duration: 1,
                yPercent: 0
            }, 'flip')
            // Now slide out each word/span
            .to(contentTitleWords, {
                duration: 0.85,
                yPercent: pos => pos ? -200 : 200,
                onComplete: () => gsap.set(contentTitle, { opacity: 0 })
            }, 'columns')

            .to([columnsInner.down, columnsInner.up], {
                y: 0
            }, 'columns')
            .to(grid, {
                scale: 0.4
            }, 'columns')
    };

    const switchActions = {
        list: showList,
        grid: showGrid
    };

    // Toggle mode events
    switchToggle.list.addEventListener('click', () => toggleMode('list'));
    switchToggle.grid.addEventListener('click', () => toggleMode('grid'));

    // Select all elements with the class 'myClass'
    const elements = document.querySelectorAll('.content__nav-item');

    // Remove 'active' class from all elements with content__nav-item class
    elements.forEach(element => {
        element.addEventListener('click', function () {
            // Remove 'active' class from all elements
            elements.forEach(el => el.classList.remove('content__nav-item--current'));
        });
    });

    // Add a click event listener to each element
    elements.forEach(element => {
        element.addEventListener('click', function () {

            // 'this' refers to the clicked element
            // You can now get the id of the clicked element
            // console.log('clicked element: ' + this.style);
            // console.log('ID of clicked element: ' + this.id);

            // Get computed style of the clicked element
            const style = window.getComputedStyle(this);
            // console.log('clicked element: ' + style);

            // Get the background url image of the clicked element
            // console.log('clicked element background image =' + style.backgroundImage);

            // Create an object to hold the style properties
            let styleObject = {};

            // Iterate over all the properties in the style object
            for (let prop of style) {
                styleObject[prop] = style.getPropertyValue(prop);
            }

            // --- Convert the style object to a JSON string ---
            // const styleJson = JSON.stringify(styleObject);
            // console.log('Computed style in JSON format:', styleJson);

            // Add the 'active' class to the clicked element
            this.classList.add('content__nav-item--current');

            // New background image URL
            const newBackgroundImageUrl = style.backgroundImage;

            // Select all elements with the class 'myClass'
            const elements = document.querySelectorAll('.column__item-img');

            // Change the backgroundImage for each element
            elements.forEach(element => {
                element.style.backgroundImage = `${newBackgroundImageUrl}`;
            });
        });
    });


    // Preload images then remove loader (loading class) from body
    preloadImages('.column__item-img').then(() => document.body.classList.remove('loading'));
}